



































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import snakeCaseKeys from 'snakecase-keys'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

type SubmitType = 'edit' | 'delete'

@Component({
  components: {
    TablePagination,
    EventUserDropdown,
    PasswordConfirmField,
    GenericModalDelete,
    GenericModalForm,
    TrashCan16,
    Edit16
  }
})
export default class ExamsPaymentAttemptsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) eventUserId!: string
  paymentAttempts = []
  totalPaymentAttempts = 0
  filteredData = []
  deleteModalIsOpened = false
  editModalIsOpened = false
  selectedPaymentAttempt = {
    id: '',
    fullfilled: false
  }

  editForm = {
    eventExamPaymentAttemptId: '',
    fullfilled: false,
    confirm: '',
    errors: {
      fullfilled: '',
      confirm: ''
    }
  }

  created () {
    this.fetch()
  }

  fetch (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })

    if (this.eventUserId) {
      axios.get(`/event/${this.eventId}/user/${this.eventUserId}/exam/payment/attempt/`)
        .then(response => {
          this.paymentAttempts = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
          this.totalPaymentAttempts = this.filteredData.length
        })
    } else {
      axios.get(`/event/${this.eventId}/exam/payment/attempt/`, {
        params: {
          'page[number]': page,
          ...perPageSnake
        }
      })
        .then(response => {
          this.paymentAttempts = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
          this.totalPaymentAttempts = response.data.total
        })
    }
  }

  onPageChange (pagination: Pagination) {
    this.fetch(pagination.page, pagination.length)
  }

  postSubmit (type: SubmitType) {
    let verb = ''
    const closeModal = {
      edit: () => {
        this.editModalIsOpened = false
        verb = 'editado'
      },
      delete: () => {
        this.deleteModalIsOpened = false
        verb = 'removido'
      }
    }

    this.clearForm()

    closeModal[type]()

    const flashMessage: flashMessage = {
      message: `O Pagamento de Prova foi ${verb} com sucesso.`,
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  private clearForm () {
    this.editForm = {
      eventExamPaymentAttemptId: '',
      fullfilled: false,
      confirm: '',
      errors: {
        fullfilled: '',
        confirm: ''
      }
    }
  }

  @Watch('selectedPaymentAttempt')
  onSelectedPaymentAttemptChange () {
    const { id, fullfilled } = this.selectedPaymentAttempt
    this.editForm.eventExamPaymentAttemptId = id
    this.editForm.fullfilled = fullfilled
  }
}
