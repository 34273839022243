


















































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import Download16 from '@carbon/icons-vue/es/download/16'
import Checkmark16 from '@carbon/icons-vue/es/checkmark/16'
import Close16 from '@carbon/icons-vue/es/close/16'

import GenericModalForm from '@/partials/modals/generic/form.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

type DocumentStatus = 'accept' | 'reject'

@Component({
  components: {
    PasswordConfirmField,
    GenericModalForm,
    Download16,
    Checkmark16,
    Close16
  }
})
export default class EventSubscriptionAttachmentsTable extends Mixins(FlashMessageMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionId!: string

  filteredData = []

  form: Record<string, any> = {
    reviewNotes: '',
    confirm: '',
    errors: {
      reviewNotes: '',
      confirm: ''
    }
  }

  updateStatusModalIsOpened = false

  selectedDocument = {
    id: ''
  }

  requestStatus = ''

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`event/${this.eventId}/subscription/${this.subscriptionId}/attachment/review`)
      .then(response => {
        this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  selectDocument (document: any, type: DocumentStatus) {
    this.selectedDocument = document
    this.requestStatus = type
    this.updateStatusModalIsOpened = true

    const defaultFormNotes = {
      accept: 'Documento em conformidade.',
      reject: 'Documento Inválido.'
    }

    this.form.reviewNotes = defaultFormNotes[type]
  }

  postSubmit (message: string) {
    this.updateStatusModalIsOpened = false
    this.form.confirm = ''

    const flashMessage: flashMessage = {
      message: message,
      type: 'success'
    }

    this.setFlashMessage(flashMessage)
    this.fetch()
  }
}
