

























import { Component, Watch, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import PaymentAttemptsTable from '@/partials/tables/Events/ExamPaymentAttempts.vue'
import SubscriptionAttachmentsReviewTable from '@/partials/tables/Events/Subscriptions/Attachments/review.vue'
import GenericModalForm from '@/partials/modals/generic/form.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    PasswordConfirmField,
    GenericModalForm,
    SubscriptionAttachmentsReviewTable,
    PaymentAttemptsTable
  }
})
export default class EventSubscriberExamPaymentAttempts extends Mixins(FlashMessageMixin) {
  done = false
  eventUserId = ''
  eventId = ''
  subscriptionId = ''
  validDocs = false
  formModalIsOpened = false
  validateDocsText = ''

  form: Record<string, any> = {
    validated: true,
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  created () {
    const { eventId, subscriptionId } = this.$route.params
    this.eventId = eventId
    this.subscriptionId = subscriptionId

    this.fetch()
  }

  private fetch () {
    this.done = false

    axios.get(`/event/${this.eventId}/subscription/${this.subscriptionId}`)
      .then(response => {
        const casedResponse = camelCaseKeys(response.data.data, { deep: true })

        // this.eventUserId = casedResponse.eventUser.id
        this.validDocs = casedResponse.validExamDocs
      })
      .finally(() => { this.done = true })
  }

  postSubmit () {
    this.form.confirm = ''

    const flashMessage: flashMessage = {
      message: this.validDocs ? 'Documentação rejeitada com sucesso.' : 'Documentação aprovada com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)
    this.formModalIsOpened = false

    this.fetch()
  }

  @Watch('validDocs', { immediate: true })
  onValidDocsChange (value: boolean) {
    if (value) {
      this.validateDocsText = 'Rejeitar Documentação'
      this.form.validated = false
    } else {
      this.validateDocsText = 'Aprovar Documentação'
      this.form.validated = true
    }
  }
}
